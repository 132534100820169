import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { QRButton } from '@base/src/app/core/models/qr-button.model';
import { QRButtonComponent } from '../../ui/qr-button/qr-button.component';

@Component({
  selector: 'app-invitation-to-register',
  templateUrl: './invitation-to-register.component.html',
  styleUrls: ['./invitation-to-register.component.scss'],
  standalone: true,
  imports: [QRButtonComponent],
})
export class InvitationToRegisterComponent {
  public readonly BUTTON_LOGIN: QRButton = {
    id: 'button-loggin',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Iniciar sesión',
  };

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  public onLogin(): void {
    if (isPlatformBrowser(this.platformId)) {
      const URL: string = window.location.href;
      localStorage.setItem('returnUrl', URL);
      this.router.navigate(['/iniciar-sesion']);
    }
  }
}
