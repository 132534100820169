export class ItemParam {
  public id: any;
  public value: any;
  public order: number;
  public i18nKey: string | undefined;
  public i18nValue: string | undefined;
  private _value2: string;

  constructor(_id = 0, _value = 'none', value2: any = undefined, order = 0) {
    this.id = _id;
    this.value = _value;
    this._value2 = value2;
    this.order = order;
  }

  get lang(): string {
    if (this._value2 !== undefined) {
      return this._value2;
    } else {
      return this.value.toString();
    }
  }

  static ItemsParam2String(items: ItemParam[]): string {
    if (items === null || items === undefined || items.length == 0) {
      return '';
    } else {
      let params = '';
      items.forEach(item => {
        params += item.id + ',';
      });
      return params.slice(0, -1);
    }
  }
}
