<div class="search">
  <div class="search__name">
    <label class="search__label" for="search-name-input">
      Nombre de tu búsqueda
    </label>
    <div class="search__input-wrapper">
      <qr-input
        [config]="textInputWithValue"
        (valueChange)="inputChange($event)"></qr-input>
    </div>
  </div>
  <p class="search__help-text">
    Podés ver y editar tus búsquedas desde tu perfil, ingresando a
    <a href="/busquedas" class="search__link">búsquedas</a>
  </p>
</div>
