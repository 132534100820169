import { QRIcon } from './qr-icon.model';

export class QRDropdown {
  // Tamaños
  public static readonly SIZE_56_PX: number = 56;
  public static readonly SIZE_48_PX: number = 48;
  size!: number;
  placeholder: string = '';
  options!: string[];
  icon?: QRIcon;
  isMultiple?: boolean = false;
  label?: string;
}
