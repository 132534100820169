import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Constants } from '@base/src/app/core/constants/constants';
import { environment } from '@base/environments/environment';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { ItemParam } from '@app/core/models/ItemParam';
import { QrFilterPrice } from '@app/core/models/qr-filter-price';
import { QRInput } from '../../core/models/qr-input.model';
import { QRInputComponent } from '../ui/qr-input/qr-input.component';
import { QRDropdownComponent } from '../ui/qr-dropdown/qr-dropdown.component';
import { QRDropdown } from '../../core/models/qr-dropdown.model';

@Component({
  selector: 'qr-filter-price-number-range',
  templateUrl: './qr-filter-price-number-range.component.html',
  styleUrls: ['./qr-filter-price-number-range.component.scss'],
  standalone: true,
  imports: [QRInputComponent, QRDropdownComponent],
})
export class QrFilterPriceNumberRangeComponent implements OnInit, OnDestroy {
  @Input() currencies: ItemParam[] = [];

  public readonly NODE: string = environment.node;

  public readonly NODE_ECUADOR: string = Constants.NODE_ECUADOR;

  public dropdownCurrency: QRDropdown = {
    size: QRDropdown.SIZE_48_PX,
    placeholder: 'Moneda',
    options: [],
  };

  public dropdownCurrencySelected: string | undefined;

  public readonly INPUT_MIN: QRInput = {
    label: 'Desde',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'from',
    id: 'from',
    placeholder: '0',
    format: QRInput.FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS,
  };

  public readonly INPUT_MAX: QRInput = {
    label: 'Hasta',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'to',
    id: 'to',
    placeholder: '0',
    format: QRInput.FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS,
  };

  @Output() onchange = new EventEmitter<QrFilterPrice>();
  public dataSelected: QrFilterPrice = new QrFilterPrice();
  private subscription: Subscription | undefined;

  @Input() set selected$(value: Observable<QrFilterPrice>) {
    if (this.subscription === undefined) {
      this.subscription = value?.subscribe(data => {
        if (!Object.is(data, this.dataSelected)) {
          this.dataSelected = data.clone();
        }
      });
    }
  }

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnInit(): void {
    this.loadCurrency();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
  }

  public dropdownCurrencyChange(e: string | string[] | undefined): void {
    const CURRENCY_SELECTED: ItemParam | undefined = this.currencies.find(
      (currency: ItemParam) => {
        return currency.value == e;
      }
    );
    if (CURRENCY_SELECTED) {
      this.dataSelected.currencyId = CURRENCY_SELECTED.id;
    }
    this.dispatchEvent();
  }

  public inputMaxChange(value: string | number | undefined): void {
    this.dataSelected.max =
      value !== null && value !== undefined ? parseFloat(value.toString()) : 0;
    this.dispatchEvent();
  }

  public inputMinChange(value: string | number | undefined): void {
    this.dataSelected.min =
      value !== null && value !== undefined ? parseFloat(value.toString()) : 0;
    this.dispatchEvent();
  }

  private dispatchEvent(): void {
    this.onchange.emit(this.dataSelected.clone());
  }

  private loadCurrency(): void {
    this.currencies.forEach((currency: ItemParam) => {
      this.dropdownCurrency.options.push(currency.value);
    });
    const CURRENCY_SELECTED: ItemParam | undefined = this.currencies.find(
      (currency: ItemParam) => {
        return currency.id == this.dataSelected.currencyId;
      }
    );
    if (CURRENCY_SELECTED) {
      this.dropdownCurrencySelected = CURRENCY_SELECTED.value;
    }
  }
}
