import { Pipe, PipeTransform } from '@angular/core';
import { L10nTranslationService } from 'angular-l10n';

@Pipe({
  name: 'arrayToString',
  standalone: true,
})
export class ArrayToStringPipe implements PipeTransform {
  constructor(private translationService: L10nTranslationService) {}

  transform(
    value: string[] | string,
    separator: string = ', ',
    isTranslationEnabled: boolean = false
  ): string {
    if (!Array.isArray(value)) {
      return '';
    }

    if (isTranslationEnabled) {
      return value
        .map(item => this.translationService.translate(item))
        .join(separator);
    }

    return value.join(separator);
  }
}
