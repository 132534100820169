<div class="filter">
  @if (NODE !== NODE_ECUADOR) {
    <qr-dropdown
      class="filter__currency"
      [config]="dropdownCurrency"
      [selected]="dropdownCurrencySelected"
      (onChange)="dropdownCurrencyChange($event)"></qr-dropdown>
  }
  <div class="filter__inputs">
    <qr-input
      class="filter__input-min"
      [config]="INPUT_MIN"
      [value]="dataSelected.min.toString()"
      (valueChange)="inputMinChange($event)"></qr-input>
    <qr-input
      class="filter__input-max"
      [config]="INPUT_MAX"
      [value]="dataSelected.max.toString()"
      (valueChange)="inputMaxChange($event)"></qr-input>
  </div>
</div>
